@import '../../../mac_base/scss/theme-bootstrap';

.elc-order-tracking {
  .elc-grid-column {
    a.elc-order-tracking-see-delivery-results {
      @include cta-primary-outline;
      margin-top: 20px;
    }
  }
}
