@mixin bopis_updates_checkout_shared {
  .checkout {
    &__sidebar {
      .order-summary-panel {
        .checkout_bopis_sidebar {
          clear: both;
          .bopis-banner {
            width: 100%;
            margin: 20px 0;
            padding: 16px;
            border: 1px solid $color--gray--tier5;
            background-color: $white;
            &__title-block {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 10px;
              h3 {
                font-size: 18px;
              }
              span,
              strong {
                font-size: 12px;
              }
            }
            &__info-block {
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-size: 12px;
            }
            &__icon {
              width: 22px;
              height: 22px;
              min-width: 22px;
              margin-left: 5px;
            }
            .bopis-more-info {
              width: 20px;
              min-width: 20px;
              height: 20px;
              line-height: 20px;
              font-size: 12px;
              display: none;
            }
          }
          .info__banner {
            display: flex;
            width: 100%;
            text-align: center;
            margin: 20px 0;
            padding: 16px;
            border: 1px solid $color--gray--tier5;
            @include breakpoint($bp--large-up) {
              padding: 16px 10px;
            }
            &--step {
              width: 33.33%;
              padding-right: 10px;
              @include breakpoint($bp--large-up) {
                padding-right: 0;
              }
            }
            &--icon {
              min-height: 35px;
            }
            &--text {
              font-size: 12px;
              margin: auto;
            }
          }
        }
      }
    }
    .checkout__index-content {
      .info__banner {
        display: none;
      }
    }
  }
}
