.panel {
  .viewcart &.links-panel,
  .checkout &.links-panel,
  .viewcart &.need-help-panel {
    display: none;
    @include breakpoint($bp--large-up) {
      display: block;
    }
  }
}

.checkout__content {
  .review-panel {
    #checkout_review {
      .legal_info_payment_content {
        @include swap_direction(margin, 18px 0 9px 0);
        a {
          text-transform: none;
        }
        .terms_bold {
          font-weight: bold;
        }
      }
    }
  }
}

.terms-condition-privacy {
  @include breakpoint($bp--large-down) {
    display: none;
  }
}

.opc__review {
  .checkout__index-content {
    .checkout__sidebar {
      &.display-footer {
        .need-help-panel {
          @include breakpoint($bp--large-down) {
            margin-bottom: 25px;
          }
        }
        .terms-condition-privacy {
          @include breakpoint($bp--large-down) {
            display: block;
            margin-bottom: 90px;
          }
          .legal_info_payment_content {
            a {
              @include breakpoint($bp--large-down) {
                text-transform: none;
              }
            }
            .terms_bold {
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}

@include breakpoint($bp--medium-down) {
  #colorbox.loyalty-appliedpopup {
    position: fixed !important;
    top: 35% !important;
    height: 200px !important;
    #cboxLoadedContent {
      height: auto !important;
      padding-top: 5em;
    }
  }
}

.continue-buttons {
  #bottom-viewcart-buttons & {
    @include breakpoint($bp--medium-up) {
      padding-#{$rdirection}: 10%;
      text-align: #{$rdirection};
    }
  }
}

.checkout {
  #offer-code-panel {
    .offer-code__input {
      input {
        background-color: $color--white;
      }
    }
  }
}

.checkout-optimization {
  &.viewcart {
    .cart-item {
      &__error--limited-qty {
        @include swap_direction(margin, 4% 0 0 35%);
        @include breakpoint($bp--medium-up) {
          @include swap_direction(margin, 14% 0 0 40%);
        }
        @include breakpoint($bp--xxlarge-up) {
          @include swap_direction(margin, 12% 0 0 41%);
        }
        p {
          @include breakpoint($bp--medium-up) {
            max-width: 116px;
          }
        }
      }
    }
  }
}

.bundle-kit {
  .cart-item__desc-info,
  .cart-item__size-shade {
    min-height: auto;
  }
  .color {
    &.column {
      padding-bottom: 20px;
    }
  }
}

.order-details-page {
  .order-products {
    .product-header {
      font-size: 15px;
      @include breakpoint($bp--xxlarge-up) {
        font-size: 18px;
      }
    }
  }
}

.viewcart {
  .continue-buttons,
  .sticky-checkout-button {
    .paypal-smart-button {
      height: 40px;
      margin-bottom: 13px;
      @include breakpoint($bp--large-up) {
        margin-bottom: 5px;
        vertical-align: middle;
      }
      &.paypal-loading {
        background: url('/media/dev/global/icon_loading.gif') no-repeat center center $color--gray--white;
        border-radius: 4px;
        position: relative;
      }
    }
  }
  &.enhanced-cart-page {
    .checkout {
      .sticky-checkout-button {
        .viewcart-buttons-panel {
          .continue-buttons {
            .payment-btn-wrapper {
              flex-direction: column;
              .paypal-smart-button {
                margin-#{$ldirection}: 0;
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
    #csr_header_holder {
      top: 0 !important;
    }
  }
}
