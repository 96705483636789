@import 'overrides/overrides';
//  @file us_pc.scss
//  \brief Pull all parts of the US-PC theme together into one file
$product-full-overview-height: 405px;
$appt_booking: true;
$opc_update: true;
$mac-lover: true;
$gnav_update: true;
$cr20: true;
$password_strengthen: true;
$replenishment_reskin: true;
$fonts_update: true;
$loyalty-redesign: true !default;

//SCSS variable configuration to include LBO related CSS specific to this locale.
$lbo-enabled: true;

@import '../../mac_base/scss/mac-base';
@import 'shared/shared';
@import 'components/_site-map';
@import 'components/_site-header';
@import 'components/_opc_page-checkout';
@import 'components/_social_login';
@import '../../mac_base/scss/onetrust_cookie/cookie_settings_ot';

// Track My Order.
@import 'standalone/elc-order-tracking';
