.custom-outline {
  outline: 1px dotted #5f93ec !important;
}

.customer-service__navigation {
  .custom-outline {
    outline: 1px dotted $color--white !important;
  }
}

.artist--products__list {
  .artist--products__carousel-slide {
    .product--teaser {
      &.custom-outline {
        border: 1px dotted #5f93ec !important;
      }
    }
  }
}

.js-elements-hidden {
  visibility: hidden;
}

.shade-picker {
  z-index: 100;
}

.shades-dropdown-visibility {
  display: block;
  opacity: 1;
}

.shade-picker .custom-outline,
.date-picker.custom-outline,
.sign-in-link.custom-outline {
  border: 1px dotted #5f93ec !important;
}

.outline-none {
  outline: none;
  border: none;
}

.fav_share_show {
  display: inline-block !important;
}

// notify me
.grid--mpp__item {
  .product--teaser {
    .product__footer {
      .product__inventory-status {
        li.temp-out-of-stock {
          padding-top: 0px;
          a.notify_me {
            margin: 0;
          }
          .temp-outof-stock_text {
            margin-top: 42px;
          }
        }
      }
    }
    &.product--not-orderable {
      .product__footer {
        .product_content_fav_mobile {
          .product__link-to-spp {
            display: none;
          }
        }
      }
    }
    .prod_inv_status-1 {
      .shade-picker__color-actions {
        .sold-out {
          display: none;
        }
      }
    }
    &.prod_inv_status-2 {
      .product__footer {
        .product__inventory-status {
          .temp-out-of-stock {
            display: block;
          }
          li {
            .temp-out-of-stock__msg {
              display: none;
            }
          }
        }
      }
    }
    &.prod_inv_status-3 {
      .product__footer {
        .product__inventory-status {
          .coming-soon {
            display: block;
          }
        }
        .product__add-to-bag {
          &.coming-soon {
            display: none !important;
          }
        }
      }
    }
    &.product--shaded {
      .product__footer {
        .product__inventory-status {
          .display__notify-me {
            display: none;
          }
        }
      }
      &.product--single-not-orderable {
        &.prod_inv_status-7 {
          .product__footer {
            .product__inventory-status {
              .sold-out {
                display: none;
              }
            }
          }
          &.all-shaded-sold-out {
            &.prod_inv_status-7 {
              .product__footer {
                .product__inventory-status {
                  .sold-out {
                    @include breakpoint($bp--medium-down) {
                      display: block;
                    }
                  }
                }
              }
            }
          }
        }
        &.prod_inv_status-2,
        &.prod_inv_status-7,
        &.prod_inv_status-3 {
          &.product--not-orderable {
            .product__footer {
              .product_content_fav_mobile {
                .product__link-to-spp {
                  @include breakpoint($bp--medium-down) {
                    display: inline-block;
                  }
                }
              }
            }
            .fade-non-shoppable-product {
              opacity: 1;
            }
            &.all-shaded-sold-out.product--single-not-orderable {
              &.product--not-orderable {
                .fade-non-shoppable-product {
                  opacity: 0.5;
                }
                .product__footer {
                  .product_content_fav_mobile {
                    .product__link-to-spp {
                      @include breakpoint($bp--medium-down) {
                        display: none;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &.all-shaded-sold-out,
    &.product--single-not-orderable {
      &.prod_inv_status-7 {
        .product__footer {
          .product__inventory-status {
            .sold-out {
              display: block;
            }
          }
        }
      }
    }
  }
}

.mobile-collection-slide {
  .product--teaser.prod_inv_status-2 {
    .product__footer {
      .product__inventory-status {
        li {
          height: auto;
          padding: 0px;
          .temp-out-of-stock__msg {
            display: none;
          }
        }
      }
    }
  }
}

.product--full {
  .product__footer {
    .product__inv-status-item.temp-out-of-stock .notify_me {
      display: none;
    }
  }
  .product__bag-bar {
    &--sticky {
      .product__inventory-status {
        .temp-out-of-stock__msg {
          display: none;
        }
      }
    }
  }
}
// end notify me

//show add to bag on temp out of stock on shaded mpp
.field-elc-mpp-content
  .grid--mpp__item
  li.shade-picker__color.prod_inv_status-2
  .temp-out-of-stock {
  display: none;
}

.grid--mpp__item {
  .prod_inv_status-2 {
    & > {
      .shade-picker__color-actions {
        a {
          display: block;
        }
        .js-inv-status-2 {
          display: none;
        }
      }
    }
  }
}

.product--not-orderable .hide-non-orderable-product {
  display: none !important;
}

.lp_desktop,
.lp_mobile {
  #lpChat {
    .lp_pages_area {
      #LP_DropDownQuestion_1,
      #LP_DropDownQuestion_2,
      #LP_DropDownQuestion_3,
      #LP_DropDownQuestion_4,
      #LP_DropDownQuestion_5,
      #LP_DropDownQuestion_6 {
        .lp_select_field {
          text-decoration: none;
          text-transform: capitalize;
          padding-right: 10%;
        }
        .lp_select_field option {
          text-transform: capitalize;
        }
        .lp_lpview_content:after {
          position: relative;
          bottom: 21px;
          font-family: 'icons';
          font-size: 24px;
          @include corner-triangle();
          content: '';
        }
      }
      ::-webkit-input-placeholder {
        text-transform: capitalize;
      }
    }
  }
}

// Waitlist Overlay Styles

.waitlist-iframe-wrapper {
  width: 95%;
  height: 250px;
  @include swap_direction(margin, 17px 7px 5px 7px);
  @include breakpoint($bp--large-up) {
    height: 190px;
  }
}

.tiny-waitlist-overlay {
  .waitlist_header {
    font-size: 20px;
    @include swap_direction(margin, 10px 0);
  }
  .field {
    .email_comp {
      .email_input {
        width: 66%;
        float: left;
        @include breakpoint($bp--large-up) {
          width: 75%;
        }
      }
      .align-r {
        width: 30%;
      }
      .align-r,
      .submit_input {
        float: $ldirection;
        margin-#{$ldirection}: 10px;
        .input-btn {
          height: 58px;
        }
      }
    }
    .terms_condition_cont {
      float: $ldirection;
      @include swap_direction(margin, 5px 5px 0 0);
      clear: both;
    }
  }
  .waitlist_thankyou_heading {
    font-size: 20px;
  }
  .waitlist_thankyou_message {
    margin-top: 10px;
  }
}

.shipping-edit-content {
  .email-and-sms-promotions {
    &__info-text {
      font-size: 11px;
      line-height: 11px;
    }
    .section-sms-promotions {
      .promotions-container {
        .sms-mobile-terms {
          font-size: 9px;
          margin-top: 5px;
          line-height: 10px;
          b {
            font-size: 10px;
          }
        }
      }
    }
  }
}

.profile-page__content {
  .sms-promotions {
    display: none;
  }
  .sms-promotions__fieldset {
    .field-container {
      @include breakpoint($bp--medium-up) {
        max-width: 100%;
        input[type='text'] {
          max-width: 660px;
        }
      }
    }
  }
}

a.invisible.focusable.invisiblefocus.custom-outline {
  clip: initial;
  position: relative !important;
  overflow: auto;
  top: 0px;
  z-index: 99999;
}

.contact-us-page {
  .contact-nav {
    @include breakpoint($bp--large-up) {
      display: flex;
      justify-content: center;
      margin: 0 auto;
      max-width: 800px;
    }
    &__videos {
      margin-bottom: 20px;
      @include breakpoint($bp--large-up) {
        margin-bottom: 0;
      }
    }
    li {
      @include breakpoint($bp--large-up) {
        width: 33.33% !important;
        margin: 0 !important;
        float: $ldirection !important;
        clear: none !important;
        padding-#{$rdirection}: 2em !important;
        min-height: 260px;
      }
      &.contact-us__email-customer-service {
        display: none;
      }
      .details {
        @include breakpoint($bp--large-up) {
          border-bottom: 0;
          min-height: auto;
        }
      }
    }
  }
}

.async-hide {
  opacity: 0;
}

.pr-qa-display-question {
  .pr-qa-display-answer-link-container {
    display: none;
  }
}

.account-order-history {
  .past-purchases {
    .past-purchases__item {
      .product-header {
        .quantity {
          margin-#{$ldirection}: 47%;
          @include breakpoint($bp--large-up) {
            margin-#{$ldirection}: 44.44%;
          }
        }
        .rating {
          margin-#{$ldirection}: 58%;
          @include breakpoint($bp--large-up) {
            margin-#{$ldirection}: 61.11%;
          }
        }
      }
    }
  }
}

.store-locator {
  .international-search {
    &:after {
      display: none;
    }
  }
}
.account-nav {
  .account-nav__section {
    .sidebar-menu__item {
      &.extole a {
        text-transform: lowercase;
        padding-top: 12px;
        display: inline-block;
      }
    }
  }
}
.site-header-formatter__offers {
  .header-offers-banner-formatter {
    height: 60px;
    @include breakpoint($bp--large-up) {
      height: 32px;
    }
    .header-offers-banner-formatter__carousel {
      display: block;
      text-align: center;
      @include breakpoint($bp--large-up) {
        display: flex;
      }
    }
    .header-offers-banner-item {
      width: auto;
      height: auto;
      padding: 0 25px;
      @include breakpoint($bp--large-up) {
        height: 32px;
        padding: 0;
      }
      .header-offers-banner-item__offer-text {
        top: auto;
        transform: none;
        @include breakpoint($bp--large-up) {
          top: 50%;
          transform: translateY(-50%);
        }
      }
      &__extole-text {
        div {
          display: inline-block;
          a {
            line-height: 1;
            font-family: $ano-bold_regular-font;
            font-size: 12px;
            font-weight: 400;
            color: $color--yellow;
            margin-#{$ldirection}: 6px;
            @include breakpoint($bp--large-up) {
              line-height: 2.1;
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}
/* stylelint-disable selector-max-class */
/* stylelint-disable selector-max-compound-selectors */
.site-header__wrapper {
  .site-header-formatter {
    .site-my-mac-v1 {
      .site-my-mac__contents-signed_in {
        .site-my-mac__contents-footer {
          .loyalty__level__details {
            .mac-select__color-tier1 {
              color: $color--bronze-generic;
            }
            .mac-select__color-tier2 {
              color: $color--silver-generic;
            }
            .mac-select__color-tier3 {
              color: $color--gold-generic;
            }
          }
        }
      }
    }
  }
}

.search-results__product-results {
  .search-results__results {
     .cr21-refresh {
       .product--teaser {
         .product__footer {
            min-height: 75px;
         }
       }
     }
  }

  .product.prod_inv_status-7 {
    .product__footer {
      .product__add-to-bag {
        display: none;
      }
    }
    .product__inventory-status {
      .product__inv-status-item.sold-out {
        display: block;
        color: $color--black;
        font-size: 12px;
        font-family: $ano_regular-font;
      }
    }
  }

  .product.prod_inv_status-2 {
    .product__inventory-status {
      .product__inv-status-item.temp-out-of-stock {
        .temp-out-of-stock__msg {
          @include breakpoint($bp--medium-up) {
            bottom: -20px;
          }
          display: block;
          color: $color--tos-red;
          font-size: 12px;
          font-family: $ano_regular-font;
          position: absolute;
          bottom: -85px;
        }
      }
    }
  }
}

.mac-select-loyalty-image {
  .mac-select-loyalty-image__upload {
    @include breakpoint($bp--medium-up) {
      max-width: none;
    }
  }
}

.mac-select-loyalty-join {
  .mac-select-loyalty-join__signin {
    .mac_select-signin {
      color: $color--white;
      text-decoration: underline;
    }
  }
}
